﻿const API = {
    BASE: 'http://bim',
    GET_ACCESS_TOKEN: '/api/forge/GetToken_2leggedExt',
    GET_ISSUES: '/api/document/GetOnSiteIssuesExt',
    GET_ISSUE_COMMENTS: '/api/document/GetOnSiteIssueCommentsExt',
    UPDATE_ISSUE: '/api/document/UpdateOnSiteIssueExt',
    UPDATE_ISSUE_MARKUP: '/api/document/UpdateOnSiteIssueMarkupExt',
    SAVE_ISSUE: '/api/document/SaveOnSiteIssueExt',
    SAVE_ISSUE_COMMENT: '/api/document/SaveOnSiteIssueCommentExt',
    SAVE_ISSUE_MARKER: '/api/document/SaveOnSiteIssueMarkerExt',
    SAVE_ISSUE_SCREENSHOT: '/api/document/SaveOnSiteIssueScreenshotExt',
    ON_MODEL_CACHED: '/api/forge/OnModelOpenedInExternalViewer',
};

const MESSAGES = {
    SUCCESS: 'Success!',
    INFO: 'Info!',
    ISSUE_CREATE_SUCCESS: 'Your issue has been created.',
    EDIT_SUCCESS: 'Your changes to the issue have been saved.',
    SET_MARKER: 'Please set a marker!',
    COMMENT_CREATE_SUCCESS: 'Comment saved successfully.',
    SCREENSHOT_CREATE_SUCCESS: 'Screenshot saved successfully.',
    MARKER_UPDATE_SUCCESS: 'Marker was saved!',
    JUST_NOW: ' just now!',
    AGO: ' ago!',
};

const DB_TABLES = {
    STATUS: { key: 'ID', description: 'Code' },
    PRIORITY: { key: 'ID', description: 'Code' },
    MEMBER: { key: 'User_ID', description: 'Username' },
};
//LAZY-LOADING
const loadNumberOfIssuesAtOnce = 20;
//FILTERS
const typingDelay = 1000; // onTitleFilterSelected fired 1 second after user stops typing
let typingTimer;
let isFilterActive = false;
const FILTERS = {
    TITLE: {
        field: 'Title',
    },
    ASSIGNEE: {
        field: 'AssignedTo',
    },
    PDF_PAGE: {
        field: 'PdfPage',
    },
    STATUS: {
        field: 'Status_ID',
        values: {
            OPEN: 1,
            CLOSED: 3,
        },
    },
    PRIORITY: {
        field: 'Priority',
        values: {
            HIGH: 1,
            MEDIUM: 2,
            LOW: 3,
        },
    },
};
let currentFilter = { mode: 'AND' };
let selectedAssigneeId = null;

//AUTH
let accessToken = null;  // Access token used by the viewer
let bimpoolToken = null;  // bimpool authentication

//STATE
const state = { isLoading: false, isQuerying: false, isInitialized: false, isPdf: false };
let currentVersion = null;
let currentApp = null, elem, viewer; // Viewing application
let currentUrn = null;  // Model URN
let currentFilename = null;

let currentPage = 1;
let currentUserId = -1;
let currentNumPages = -1;
let currentRcode = null;
let currentInputFileType = null;
let currentEditContext = null;

let currentIssues = [];
let filteredIssues = [];

let currentDetailIssueId = null;
let currentEditIssue = null;
let selectedImage = null;

//MARKUP
let editingIssueMarker = false;
let markupExtension = null, markupCore = null;
let currentScreenshotAndMarkup = null;

//MARKER
let currentMarkerPoint = null;
let confirmedMarkerPoint = null;
let markerSvg = null;
let markersVisible = false;

window.onload = async () => {
    try {
        if(!window.location.href.includes('MobileViewer')) {
            return;
        }
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        API.BASE = apiBaseUrl();
        currentVersion = await getVersion();
        $('#versionInfo').text(currentVersion);

        elem = $('#label')[0];
        viewer = new Autodesk.Viewing.Private.GuiViewer3D($('#externalViewer')[0]);
        this.viewer = viewer;
        const options = {   //better not provide "env" & "api"
            getAccessToken: function (callback) {
                const opts = {
                    headers: {
                        'token':  bimpoolToken
                    }
                };
                fetch(`${API.BASE}${API.GET_ACCESS_TOKEN}`, opts)
                    .then((response) => response.json())
                    .then((json) => {
                        accessToken = json.access_token;
                        callback(json.access_token, 15 * 60 /* use token for 15 min */);
                    })
            },
        };
        currentUrn = params.urn;
        bimpoolToken = params.token;
        currentRcode = params.rcode;
        currentFilename = params.fname;
        initListeners();
        watchState();

        if(currentUrn && currentUrn.length > 160) {

            Autodesk.Viewing.Initializer(options, function() {
                viewer = new Autodesk.Viewing.GuiViewer3D($('#externalViewer')[0]);
                const startedCode = viewer.start();
                if(startedCode > 0) {
                    console.error('Failed to create a Viewer: WebGL not supported.');
                    return;
                }
                const documentId = 'urn:' + currentUrn;
                Autodesk.Viewing.Document.load(documentId, onDocumentLoadSuccess, onDocumentLoadFailure);
            });
        }
        else
        if(currentRcode && currentRcode.length > 0) {

            state.isPdf = true;
            await Autodesk.Viewing.Initializer({
                loaderExtensions: { svf: "Autodesk.MemoryLimited" },
                memory: {
                    limit: 1024 * 8,
                },
                extensions: ['Autodesk.PDF'],
                getAccessToken: function (callback) {
                    const opts = {
                        headers: {
                            'token':  bimpoolToken
                        }
                    };
                    fetch(`${API.BASE}${API.GET_ACCESS_TOKEN}`, opts)
                        .then((response) => response.json())
                        .then((json) => {
                            accessToken = json.access_token;
                            callback(json.access_token, 15 * 60 /* use token for 15 min */);
                        })
                },
            });
            await viewer.loadExtension('Autodesk.PDF');
            loadPdf();
        }
        getIssuesExt();
    }
    catch(exc) {
        exceptionOccurred(exc, 'window.onload()');
    }
}

function loadPdf() {
    const path = `externalviewer/${currentRcode}/annotations/${bimpoolToken}.pdf`;
    viewer.start();
    return viewer.loadModel(path, {page: currentPage}, onDocumentLoadSuccess, onDocumentLoadFailure);
}

async function updateStatus() {
    // If [Storage Manager API](https://developer.mozilla.org/en-US/docs/Web/API/StorageManager)
    // is available, update the used/quota numbers
    if('storage' in navigator) {
        navigator.storage.estimate().then(function (estimate) {
            const usage = (estimate.usage / Math.pow(2, 20)).toFixed(2);
            const quota = (estimate.quota / Math.pow(2, 20)).toFixed(2);
            console.log("estimated cache storage",`${usage}MB/${quota}MB`);
        });
    }
}

async function onDocumentLoadSuccess(doc) {
    try {
        viewer.setTheme("light-theme");
        viewer.start();

        if(doc.loader && doc.loader.isPdfLoader) {
            currentNumPages = doc.loader.pdf._pdfInfo.numPages;
            currentInputFileType = 'pdf';
            init2dMarkupExtension();
        } else {
            const defaultModel = doc.getRoot().getDefaultGeometry();
            await viewer.loadDocumentNode(doc, defaultModel);
            const { name, inputFileType } = doc.docRoot.data.children[0];

            currentFilename = name;
            currentInputFileType = inputFileType;

            if(inputFileType == 'dwg') {
                init2dMarkupExtension();
            }

            if(inputFileType == 'ifc') {
                init3dMarkerExtension();
            }
        }

        if(currentFilename) {
            $('#filename').text(currentFilename);
        }
        enablePdfControls();
    } catch(exc) {
        exceptionOccurred(exc, arguments.callee.name);
    }
}

function onDocumentLoadFailure(err) {
    updateStatus();
    enablePdfControls();
    console.error('Could not load document ' + err);
}

async function init2dMarkupExtension (e) {
    try {
        if(currentFilename.toLowerCase().endsWith('.pdf') && currentNumPages > 1) {
            $('.pdf-control').removeClass('d-none');
            enablePdfControls();
        }
        markupExtension = await viewer.loadExtension("XCOMarkup", { context: "docs_preview" });
        markupCore = markupExtension.core;
    } catch(exc) {
        exceptionOccurred(exc, arguments.callee.name);
    }
}

function init3dMarkerExtension(e) {
    window.devicePixelRatio = 1;
    viewer.setBackgroundColor(0,0,0, 155,155,155);
    viewer.impl.toggleGroundShadow(true);
    viewer.loadExtension("markup3d");
    init3dMarkers();
    updateStatus();
}

async function initIssueMarkup() {
    try {
        $('#issuesModal').modal('hide');
        currentScreenshotAndMarkup = await getViewerScreenshot();

        if(typeof currentScreenshotAndMarkup === 'string') {    //no markup-case
            currentScreenshotAndMarkup = {
                screenshot: ''+currentScreenshotAndMarkup,
                markup: { page: currentPage, viewerState: ''},
            }
        }

        // $('#screenshotPreview').attr('src', currentScreenshotAndMarkup.screenshot);
        $('#newIssueModal').modal('show');
    } catch(exc) {
        exceptionOccurred(exc, arguments.callee.name);
    }
}

async function initEditIssueMarkup({target}) {
    try {
        hideAllMarkers(false);
        const targetIsIcon = $(target).prop('nodeName').toLowerCase() === 'i';

        if(targetIsIcon) {
            target = $(target).closest('.issueControlButton')[0];
        }

        const issueId = $(target).data('issue-id');
        if(currentEditContext != 'marker') {
            const context = $(target).data('context');
            currentEditContext = context;
        }
        let issueToEdit = $.grep(currentIssues, issue => issue.ID === issueId)[0];
        currentEditIssue = issueToEdit;

        const Coordinates = JSON.parse(issueToEdit.Coordinates);
        const { ModelMarkup: markupSVG } = issueToEdit;
        const {viewerState} = Coordinates;
        let page = 1;

        if(currentInputFileType == 'pdf') {
            page = Coordinates.page;
        }

        const markupCode = 'Markup_'+ issueToEdit.ID;

        if(currentPage !== page && currentInputFileType) {
            currentPage = page;
            await loadPdf();
        }

        const markupCore = await viewer.getExtension("Autodesk.Viewing.MarkupsCore");
        markupExtension.activate();
        markupCore.activate();
        markupCore.unloadMarkupsAllLayers();

        $(document).one(markupExtension.EDIT_MODE_OFF, () => {
            console.log("markupExtension.EDIT_MODE_OFF")
        });

        await restoreStatePromise(viewerState);
        markupCore.show();
        markupCore.leaveEditMode();
        markupCore.loadMarkups(markupSVG, markupCode);
        markupCore.enterEditMode(markupCode);
        enableEditIssueMarkupMode();
    } catch(exc) {
        exceptionOccurred(exc, arguments.callee.name);
    }
}

async function restoreStatePromise(viewerState,callback) {
    var restorePromise = new Promise(function (resolve, reject) {
        var listener = function (event) {
            if(event.value.finalFrame) {
                viewer.removeEventListener(
                    Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT,
                    listener
                );
                resolve();
            }
        }
        // Wait for last render caused by camera changes
        viewer.addEventListener(
            Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT,
            listener
        );

        viewer.restoreState(viewerState);
    });
    await restorePromise;
    if(callback)
        callback();
}

function enableEditIssueMarkupMode() {
    $('#menu button:not(.markupControl)').prop('disabled', true);
    $('.markupControl').removeClass('d-none');
    $('.modal').modal('hide');
}

function disableEditIssueMarkupMode() {
    currentEditIssue = null;
    markupExtension.deactivate();
    $('#menu button').prop('disabled', false);
    $('.markupControl').addClass('d-none');
    enablePdfControls();
    // $('#issuesModal').modal('show');
}

async function submitMarkup() {
    currentScreenshotAndMarkup = await getViewerScreenshot();

    let coords = null;
    if(currentInputFileType == 'pdf') {
        coords = JSON.stringify( {
            page: currentPage,
            viewerState: viewer.getState(),
        } );
    } else {
        coords = JSON.stringify( {
            viewerState: viewer.getState(),
        } );
    }

    const issue = {
        Topic_ID: currentEditIssue.ID,
        Snap: currentEditIssue.Snap,
        Screenshot: currentScreenshotAndMarkup.screenshot,
        Markup: currentScreenshotAndMarkup.markup,
        Coordinates: coords,
    }
    await updateMarkupExt(issue);
    disableEditIssueMarkupMode();
    returnToPreviousContext();
}

function cancelMarkup() {
    disableEditIssueMarkupMode();
    returnToPreviousContext();
}

function onEditCanceled() {

}

function onEditConfirmed() {

}

function returnToPreviousContext() {
    if(markersVisible) {
        showAllMarkers();
    }

    if(!currentEditContext || typeof currentDetailIssueId != 'number' || !currentDetailIssueId) {
        return;
    }

    if(currentEditContext == 'list') {
        $('#issuesModal').modal('show');
    } else if(currentDetailIssueId && (currentEditContext == 'detail') || currentEditContext == 'marker') {
        initComments(currentDetailIssueId);
    }
}

function init3dMarkers(){
    // create 20 random markup points
    // where icon is 0="Issue", 1="BIMIQ_Warning", 2="RFI", 3="BIMIQ_Hazard"
    var dummyData = [];
    for (let i=0; i<22; i++) {
        const icon = Math.round(Math.random()*3);
        const x = Math.random()*300-150;
        const y = Math.random()*50-20;
        const z = Math.random()*150-130;
        const marker = {icon, x, y, z};
        dummyData.push(marker);
    }

    window.dispatchEvent(new CustomEvent('newData', {'detail': dummyData}));

    function moveLabel(p) {
        elem.style.left = ((p.x + 1)/2 * window.innerWidth) + 'px';
        elem.style.top =  (-(p.y - 1)/2 * window.innerHeight) + 'px';
    }
    // listen for the 'Markup' event, to re-position our <DIV> POPUP box
    window.addEventListener("onMarkupMove", e=>{moveLabel(e.detail)}, false)
    window.addEventListener("onMarkupClick", e=>{
        elem.style.display = "block";
        moveLabel(e.detail);
        elem.innerHTML = `<img src="res/img/${(e.detail.id%6)}.jpg"><br>Markup ID:${e.detail.id}`;
    }, false);
}

function initListeners() {
    $('#showAllMarkers').on('click', showAllMarkers);
    $('#hideAllMarkers').on('click', hideAllMarkers);
    $('#previousPdfPage').on('click', setPreviousPdfPage);
    $('#nextPdfPage').on('click', setNextPdfPage);
    $('#cancelMarkup').on('click', cancelMarkup);
    $('#submitMarkup').on('click', submitMarkup);
    $('#cancelMarker').on('click', cancelMarker);
    $('#submitMarker').on('click', submitMarker);
    $('#marker').on('click', initMarker);
    $('#newCommentForm').on('submit', submitNewCommentForm);
    $('#newIssueForm').on('submit', submitNewIssueForm);
    $('#editIssueForm').on('submit', submitEdit);
    $('#cancelEdit').on('click', cancelEdit);
    $('#comment, #newComment').on('input', onCommentChanged);
    $('#newIssueCameraUpload, #newIssueStorageUpload, #newCommentCameraUpload, #newCommentStorageUpload').on('change', onFileSelected);
    $('#editIssueModal').on('hidden.bs.modal', resetForm('editIssueForm'));
    $('#hideComments').on('click', hideComments);
    $('#filters').on('show.bs.collapse', onFiltersShown);
    $('#filters').on('hide.bs.collapse', onFiltersHidden);
    $('#title, #editTitle').on('keydown', preventFormSubmit);
    $('#title').on('input', onTitleChanged);
    $('#titleFilter').on('input', onTitleFilterChanged);
    $('#assigneeFilter').on('change', onAssigneeFilterChanged);
    $('#assignedToMe').on('input', onAssignedToMeFilterChanged)
    $('#priorityLow, #priorityMedium, #priorityHigh, #statusOpen, #statusClosed').on('change', onCheckboxFilterChanged);
}

function onFiltersShown() {
    $('#showFilters').addClass('d-none');
    $('#hideFilters').removeClass('d-none');
}

function onFiltersHidden() {
    $('#showFilters').removeClass('d-none');
    $('#hideFilters').addClass('d-none');
}

function hideComments() {
    currentDetailIssueId = null;

    if(!currentEditContext ||currentEditContext == 'marker') {
        $('#commentsModal').modal('hide');
        $('#issuesModal').modal('hide');
        return;
    }

    $('#commentsModal').modal('hide');
    $('#issuesModal').modal('show');
}

function onTitleChanged(event) {
    if(event.target.value.length > 0) {
        $('#newIssueSubmit').prop('disabled', false);

    } else {
        $('#newIssueSubmit').prop('disabled', true);
    }
}

function onCommentChanged(event) {
    if(event.target.value.length == 0) {
        disableFilePickers();
    } else {
        enableFilePickers();
    }
}

function onTitleFilterChanged(event) {
    const value = event.target.value;

    if(value.length == 0) {
        onTitleFilterSelected(event);
        clearTimeout(typingTimer);
        return;
    }

    clearTimeout(typingTimer);
    typingTimer = setTimeout(function() {
        onTitleFilterSelected(event);
    }, typingDelay);
}

function onTitleFilterSelected(event) {
    event.stopPropagation();
    event.preventDefault();

    const { field } = FILTERS.TITLE;
    const target = $('#titleFilter')[0];
    const { value } = target;

    if(value.length > 0) {
        currentFilter[field] = value;
    } else {
        delete currentFilter[field];
    }
    getFilteredIssues();
}

function onAssignedToMeFilterChanged(event) {
    const isChecked = $(this).is(':checked');

    const { field } = FILTERS.ASSIGNEE;
    const value = currentUserId;

    if(!value) {
        return;
    }


    if(isChecked) {
        $('#assigneeFilter')[0].disabled = true;
        currentFilter[field] = value;
    } else {
        $('#assigneeFilter')[0].disabled = false;
        delete currentFilter[field];
    }
    getFilteredIssues();
}

function onAssigneeFilterChanged({target}) {
    const { field } = FILTERS.ASSIGNEE;
    const { value } = target;

    delete currentFilter[field];

    if(!value) {
        $('#assignedToMe')[0].disabled = false;
        $('#assignedToMe').parent().removeClass('disabled');
        getFilteredIssues();
        return;
    }

    $('#assignedToMe')[0].disabled = true;
    $('#assignedToMe').parent().addClass('disabled');
    selectedAssigneeId = value;
    currentFilter[field] = value;
    getFilteredIssues();
}

function onCheckboxFilterChanged(event) {
    event.preventDefault();
    event.stopPropagation();

    const { id, name } = this;
    const type = name.toUpperCase();
    const valueCode = id.toUpperCase().replace(type, '');

    const { field, values } = FILTERS[type];
    const isChecked = $(this).is(':checked');
    const value = values[valueCode];

    $(`input[name=${name}]`).not(`#${id}`).prop('checked',false)

    if(isChecked) {
        currentFilter[field] = value;
    } else {
        delete currentFilter[field];
    }
    getFilteredIssues();
}

//LAZY-LOADING
function onScroll({target}) {
    const container = target;
    const scrollTop = container.scrollTop;
    const scrollHeight = container.scrollHeight;
    const clientHeight = container.clientHeight;
    if(!state.isLoading && scrollTop + clientHeight >= scrollHeight - 10) {
        getMoreIssues();
    }
}

//HTML CONSTRUCTION
function renderCurrentIssues() {
    $('#issueCards').html(spinnerHtml());
    $('#issueCards').html(issuesHtml(currentIssues));
    currentEditContext = 'list';
    initIssueListeners();
}

function initIssueListeners() {
    $('.editMarkerButton').on('click', initEditIssueMarker);
    $('.editMarkupButton').on('click', initEditIssueMarkup);
    $('.editIssueButton').on('click', initEditIssue);
    $('.initCommentsButton').on('click', function({target}) {
        const issueId = $(target).data('issue-id');
        initComments(issueId);
    });
}

function issuesHtml(issues) {
    if(issues.length == 0) {
        return ' — no issues available — ';
    }

    const issueCards =  issues.map((issue) => {
        issue.ModifiedDate = issue.ModifiedDate || issue.CreationDate;
        return issueCardHtml(issue);
    });
    return issueCards.join('');
}

function issueCardHtml(issue) {
    const statusBadge = issue && issue.StatusCode ? statusBadgeHtml(issue.StatusCode) : '';
    const priorityBadge = priorityBadgeHtml(issue.PriorityCode);
    const deadlineBadge = deadlineBadgeHtml(issue.Deadline);
    const assigneeBadge = assigneeBadgeHtml(issue);
    const createdByBadge = createdByBadgeHtml(issue);
    const snapshot = snapshotHtml(issue);

    return `
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div class="card mb-3">
                <div class="imageContainer">
                    <div class="statusBar">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="issue-status">
                                ${priorityBadge}
                                ${statusBadge}
                            </div>
                            <div class="issueControls">
                                <span class="issueControlButton editMarkerButton" data-issue-id="${issue.ID}" data-context="list"><i class="fa fa-map-marker"></i></span>
                                <span class="issueControlButton editMarkupButton" data-issue-id="${issue.ID}" data-context="list"><i class="fa fa-pen"></i></span>
                                <span class="issueControlButton editIssueButton" data-issue-id="${issue.ID}" data-context="list"><i class="fa fa-pen-to-square"></i></span>
                            </div>
                        </div>
                    </div>
                    ${snapshot}
                </div>
                <div class="issue-detail-container p-2">
                    <div class="d-flex justify-content-between align-items-center">
                        <h5 class="issueTitle">${issue.Title}</h5>
                    </div>
                    <div class="d-flex justify-content-between align-items-center pt-1">
                        <div class="d-flex">
                            ${deadlineBadge}
                        </div>
                        <div class="d-flex">
                            ${assigneeBadge}
                        </div>
                    </div>
                    <div class="d-flex justify-content-between align-items-center pt-2">
                        ${createdByBadge}
                    </div>

                    <div class="mt-3 d-flex btn-group">
                        <button class="btn btn-primary initCommentsButton" data-issue-id="${issue.ID}" data-bs-dismiss="modal">
                            <img width="24" height="24" src="res/mdi/comment-multiple.svg" alt="SHOW COMMENTS">
                            COMMENTS
                        </button>
                    </div>
                </div>
            </div>
        </div>
    `;
}

function issueDetailHtml(issue, showControls = true) {
    const status = statusBadgeHtml(issue.StatusCode);
    const priority = priorityBadgeHtml(issue.PriorityCode);
    const deadlineBadge = deadlineBadgeHtml(issue.Deadline);
    const assigneeBadge = assigneeBadgeHtml(issue);
    const createdByBadge = createdByBadgeHtml(issue);

    let controlsHtml = '';
    if(showControls) {
        controlsHtml = `
            <div class="issueControls">
                <span class="issueControlButton editMarkerButton" data-issue-id="${issue.ID}" data-context="detail"><i class="fa fa-map-marker"></i></span>
                <span class="issueControlButton editMarkupButton" data-issue-id="${issue.ID}" data-context="detail"><i class="fa fa-pen"></i></span>
                <span class="issueControlButton editIssueButton" data-issue-id="${issue.ID}" data-context="detail"><i class="fa fa-pen-to-square"></i></span>
            </div>
        `;
    }

    return `
        <div class="card">
            <div class="container p-2 text-center">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="issue-status">
                        ${priority}
                        ${status}
                    </div>
                    ${controlsHtml}
                </div>
                <div class="d-flex justify-content-between align-items-center pt-1">
                    <h2>${issue.Title}</h2>
                </div>
                <div class="d-flex justify-content-between align-items-center pt-1">
                    <div class="d-flex">
                        ${deadlineBadge}
                    </div>
                    <div class="d-flex">
                        ${assigneeBadge}
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center pt-2">
                    ${createdByBadge}
                </div>
            </div>
        </div>
    `;
}

function statusBadgeHtml(statusCode) {
    const statusData = {
        'OPEN': {
            badgeClass: 'statusOpen',
            icon: 'res/mdi/flag-outline.svg',
            text: 'OPEN'
        },
        'INPROGRESS': {
            badgeClass: 'statusInProgress',
            icon: 'res/mdi/flag.svg',
            text: 'IN PROGRESS'
        },
        'CLOSED': {
            badgeClass: 'statusClosed',
            icon: 'res/mdi/flag-checkered.svg',
            text: 'CLOSED'
        },
        'FEEDBACK': {
            badgeClass: 'statusFeedback',
            icon: 'res/mdi/flag-checkered.svg',
            text: 'FEEDBACK'
        },
        'APPROVED': {
            badgeClass: 'statusApproved',
            icon: 'res/mdi/flag-checkered.svg',
            text: 'APPROVED'
        }
    };

    if(!statusCode || !statusData.hasOwnProperty(statusCode.toUpperCase())) {
        return '<span class="badge rounded-pill bg-primary"><img style="display: inline" width="18" height="18" src="res/mdi/flag-outline.svg" alt="STATUS">— no status —</span>';
    }

    const status = statusData[statusCode.toUpperCase()];
    const icon = status.icon ? `<img style="display: inline" width="18" height="18" src="${status.icon}" alt="STATUS">` : '';

    return `<span class="badge rounded-pill ${status.badgeClass}">${icon}${status.text}</span>`;
}

function priorityBadgeHtml(priorityCode) {
    const priorityData = {
        'HIGH': {
            badgeClass: 'priorityHigh',
            icon: 'res/mdi/circle.svg',
            text: 'HIGH'
        },
        'MEDIUM': {
            badgeClass: 'priorityMedium',
            icon: 'res/mdi/circle-half-full.svg',
            text: 'MEDIUM'
        },
        'LOW': {
            badgeClass: 'priorityLow',
            icon: 'res/mdi/circle-outline.svg',
            text: 'LOW'
        },
    };

    if(!priorityCode || !priorityData.hasOwnProperty(priorityCode.toUpperCase())) {
        return '<span class="badge rounded-pill bg-primary">— no priority —</ span>';
    }

    const priority = priorityData[priorityCode.toUpperCase()];
    const icon = priority.icon ? `<img style="display: inline" width="18" height="18" src="${priority.icon}" alt="PRIORITY">` : '';

    return `<span class="badge mb-1 rounded-pill ${priority.badgeClass}">${icon}${priority.text}</span>`;
}

function deadlineBadgeHtml(deadline) {
    if(deadline) {
        const deadlineDate = new Date(deadline);
        if(deadlineDate.getFullYear() > 1970)
            return `<span class="badge rounded-pill bg-primary" ><img style="display: inline" width="18" height="18" src="res/mdi/calendar-alert.svg" alt="NO DEADLINE">` + deadlineDate.toDateString() + `</span>`
    }
    return `<span class="badge rounded-pill bg-primary"><img style="display: inline" width="18" height="18" src="res/mdi/calendar-alert.svg" alt="DEADLINE">— no deadline —</span>`;
}

function assigneeBadgeHtml(issue) {
    if(!issue.AssignedTo) {
        return `<span class="badge rounded-pill bg-primary"><img style="display: inline" width="18" height="18" src="res/mdi/account-remove.svg" alt="ASSIGNEE">— unassigned —</span>`;
    }
    return `
    <span class="badge rounded-pill bg-primary">
        ${issue.AssignedToUsername}
        <img width="18" height="18" src="res/mdi/account-arrow-left.svg" alt="ASSIGNEE">
    </span>`;
}

function createdByBadgeHtml(issue) {
    const timeAgo = getCreationTimeAgo(issue.CreationDate);

    if(issue.CreatedByUsername) {
        return `
        <div>
            — created by
                <img width="18" height="18" src="res/mdi/account.svg" alt="AUTHOR">
                ${issue.CreatedByUsername} ${timeAgo} —
        </div>`;
    }
    return `
        <div>— ${timeAgo} —</div>`;
}

function getCreationTimeAgo(creationDate) {

    if(!creationDate)
        return MESSAGES.JUST_NOW;

    const currentDate = new Date();
    creationDate = new Date(creationDate);

	//apply UTC-timezone
    creationDate.setMinutes(creationDate.getMinutes() - currentDate.getTimezoneOffset());

    const timeDifference = Math.abs(currentDate - creationDate);
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hoursDifference = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutesDifference = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

    let daysAgo = '', hoursAgo = '', minutesAgo = '';
    let exactTimeAgo = '';

    if(daysDifference == 0 && hoursDifference == 0 && minutesDifference == 0) {
        return MESSAGES.JUST_NOW;
    }
    if(daysDifference > 0) {
        daysAgo = `${daysDifference} day${daysDifference > 1 ? 's' : ''} `;
        exactTimeAgo += daysAgo;
    }
    if(hoursDifference > 0) {
        hoursAgo = `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} `;
        exactTimeAgo += hoursAgo;
    }
    if(minutesDifference > 0) {
        minutesAgo = `${minutesDifference} minute${minutesDifference > 1 ? 's' : ''}`;
        exactTimeAgo += minutesAgo;
    }

    if(daysDifference == 0 && hoursDifference == 0 && minutesDifference > 0 && minutesDifference < 60)
        return minutesAgo.trim() + MESSAGES.AGO;

    if(daysDifference == 0 && hoursDifference > 0 && hoursDifference < 24)
        return hoursAgo.trim() + MESSAGES.AGO;

    if(daysDifference > 0)
        return daysAgo.trim() + MESSAGES.AGO;

    return exactTimeAgo.trim() + MESSAGES.AGO;
}

function parsePreviewImagePath(filenamesJson) {
    try {
        let imgPath = '';
        //handle base64
        if(typeof filenamesJson == 'string' && filenamesJson.startsWith('data')) {
            imgPath = filenamesJson;
            return imgPath;
        }
        //handle network path
        if(filenamesJson) {
            const { filenames } = JSON.parse(filenamesJson);
            if(filenames) {
                imgPath = filenames[0];
                return `${API.BASE}/api/SAVEFILE/GetFileExt?path=${imgPath}&token=${bimpoolToken}`
            }
        }
        return 'res/img/placeholder.svg';
    } catch(exc) {
        exceptionOccurred(exc, 'parsePreviewImagePath');
    }
}

function snapshotHtml(issue) {
    //handle base64
    if(issue.IssuePreview && typeof issue.IssuePreview == 'string' && issue.IssuePreview.startsWith('data')) {
        return `<div class="cardImageContainer"><img class="img-fluid rounded pointer cardImage initCommentsButton" src="${issue.IssuePreview}" alt="SNAPSHOT" data-bs-dismiss="modal" /></div>`;;
    }
    const path = parsePreviewImagePath(issue.IssuePreview ? issue.IssuePreview : issue.Snapshot);
    if(path)
        return `<div class="cardImageContainer"><img class="img-fluid rounded pointer cardImage initCommentsButton" src="${path}" alt="SNAPSHOT" data-issue-id="${issue.ID}" data-bs-dismiss="modal" /></div>`;
    return `<div class="cardImageContainer"><img class="img-fluid rounded pointer cardImage initCommentsButton" src="res/img/placeholder.svg" alt="SNAPSHOT" data-bs-dismiss="modal" /></div>`;;
}

function spinnerHtml(withText = true) {
    let text = withText ? '<strong>Loading...</strong>' : '';

    return `<div class="d-flex align-items-center">
        ${text}
        <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
    </div>`;
}

function initDropdownOptions(status, priorities, members) {
    const statusOptions = getSelectOptions(status, DB_TABLES.STATUS.key, DB_TABLES.STATUS.description);
    const priorityOptions = getSelectOptions(priorities, DB_TABLES.PRIORITY.key, DB_TABLES.PRIORITY.description);
    const memberOptions = getSelectOptions(members, DB_TABLES.MEMBER.key, DB_TABLES.MEMBER.description);

    $('#status, #editStatus').html(statusOptions);
    $('#priority, #editPriority').html(priorityOptions);
    $('#assignee, #editAssignee, #assigneeFilter').html(memberOptions);
}

async function submitNewIssueForm(event) {
    try {
        event.preventDefault();
        event.stopPropagation();
        const form = $('#newIssueForm')[0];

        if(form.checkValidity() === false) {
            form.classList.add('was-validated');
        } else {
            $('#newIssueSubmit').prop('disabled', true);
            const Title = form.elements['title'].value;
            const Deadline = form.elements['deadline'].value || null;
            const Priority = form.elements['priority'].value || null;
            const Status_ID = form.elements['status'].value || null;
            const AssignedTo = form.elements['assignee'].value || null;
            const Comment = form.elements['comment'].value || null;
            const Coordinates = (currentInputFileType == 'pdf') ? JSON.stringify( {
                page: currentPage,
                viewerState: viewer.getState(),
            } ) : JSON.stringify( {
                viewerState: viewer.getState(),
            } );
            const Tags = await getMentionTags('comment');
            const Marker = confirmedMarkerPoint || null;
            let Screenshot = null, Markup = null;

            if(currentScreenshotAndMarkup) {
                Screenshot = currentScreenshotAndMarkup.screenshot;
                Markup = currentScreenshotAndMarkup.markup;
            } else {
                const screenshotAndMarkup = await getViewerScreenshot()
                Screenshot = screenshotAndMarkup.screenshot;
                Markup = screenshotAndMarkup.markup;
            }

            const comment = { Comment, Tags }

            let issue = {
                Title, Deadline, Priority, Status_ID, AssignedTo,
                comment,
                // OnSiteAttachment: selectedImage || null,
                Coordinates, Marker,
                Screenshot, Markup,
                rcode: currentRcode, filename: currentFilename,
                phantomId: generateRandomString(8),
            };
            createIssueExt(issue);
        }
    } catch(exc) {
        exceptionOccurred(exc, arguments.callee.name);
    }
}
// UI manipulation
function disableSubmitButtons() {
    $('input[type="submit"]').prop('disabled', true);
    $('.saveIcon').addClass('d-none');
    $('.spinner').removeClass('d-none');
}

function enableFilePickers() {
    $('#commentInfoStepOne').addClass('d-none');
    $('#commentInfoStepTwo').removeClass('d-none');

    $('[for="newCommentCameraUpload"]').removeClass('disabled');
    $('[for="newCommentStorageUpload"]').removeClass('disabled');
    $('[for="newIssueStorageUpload"]').removeClass('disabled');
    $('[for="newIssueCameraUpload"]').removeClass('disabled');
    $('#newCommentSubmit').prop('disabled', false);
}

function disableFilePickers() {
    $('#commentInfoStepTwo').addClass('d-none');
    $('#commentInfoStepOne').removeClass('d-none');

    $('[for="newCommentCameraUpload"]').addClass('disabled');
    $('[for="newCommentStorageUpload"]').addClass('disabled');
    $('[for="newIssueStorageUpload"]').addClass('disabled');
    $('[for="newIssueCameraUpload"]').addClass('disabled');
    $('#newCommentSubmit').prop('disabled', true);
}

function onFileSelected(event) {
    event.preventDefault();
    event.stopPropagation();
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function(event) {
        const base64 = event.target.result;
        selectedImage = base64;
        if(selectedImage) {
            $('.fileSelectorIcon').addClass('d-none');
            $('.fileSelectedIcon').removeClass('d-none');
        }
    };
    reader.readAsDataURL(file);
}

function getSelectOptions(data, valueKey, textKey) {
    let options = '<option value=""></option>';
    data.forEach(function(item) {
      options += `<option value="${item[valueKey]}">${item[textKey]}</option>`;
    });
    return options;
}

function resetForm(id) {
    if(markupExtension)
        markupExtension.deactivate();

    const form = $(`#${id}`);
    form[0].reset();
    form.removeClass('was-validated');
    $('.fileSelectorIcon').removeClass('d-none');
    $('.fileSelectedIcon').addClass('d-none');
    $('.markerSelectorIcon').removeClass('d-none');
    $('.markerSelectedIcon').addClass('d-none');
    $('.btn-file-picker').prop('disabled', true);
    $('#screenshotPreview').attr('src', '');
    $('#comment').val('');
    $('#newComment').val('');
    $('#newCommentSubmit').prop('disabled', true);
    $('#newIssueSubmit').prop('disabled', true);
    disableFilePickers();
    selectedImage = null;
    confirmedMarkerPoint = null;
}

function initEditIssue(event) {
    event.preventDefault();
    event.stopPropagation();
    let { target } = event;
    const targetIsIcon = $(target).prop('nodeName').toLowerCase() === 'i';

    if(targetIsIcon) {
        target = $(target).closest('.issueControlButton')[0];
    }

    const issueId = $(target).data('issue-id');
    if(currentEditContext != 'marker') {
        const context = $(target).data('context');
        currentEditContext = context;
    }
    currentDetailIssueId = issueId;
    let issue = getIssueById(issueId);
    const formattedDeadline = issue.Deadline ? issue.Deadline.split('T')[0] : '';
    const form = $('#editIssueForm');
    form.find('#editTitle').val(issue.Title);
    form.find('#editDeadline').val(formattedDeadline);
    form.find('#editPriority').val(issue.Priority);
    form.find('#editStatus').val(issue.Status_ID);
    form.find('#editAssignee').val(issue.AssignedTo);
    $('.modal').modal('hide');
    $('#editIssueModal').modal('show');
}

//FORM SUBMIT handling
function preventFormSubmit(event) {
    if(event.keyCode === 13) {
        event.preventDefault();
    }
}

function submitEdit(event) {
    event.preventDefault();
    event.stopPropagation();
    const form = $('#editIssueForm')[0];

    if(!currentDetailIssueId) {
        return;
    }

    if(form.checkValidity() === false) {
        form.classList.add('was-validated');
    } else {
        const Topic_ID = currentDetailIssueId;
        const Title = $('#editTitle').val() || '';
        const Deadline = $('#editDeadline').val() || null;
        const Priority_ID = $('#editPriority').val() || -1;
        const Status = $('#editStatus option:selected').text().trim() || '';
        const Status_ID = $('#editStatus').val() || -1;
        const Assignee_ID = $('#editAssignee').val() || -1;

        let issue = { Topic_ID, Title, Deadline, Priority_ID, Status, Status_ID, Assignee_ID };
        updateIssueExt(issue);
    }
}

function cancelEdit() {
    $('#editIssueModal').modal('hide');
    returnToPreviousContext();
}

async function submitNewCommentForm(event) {
    event.preventDefault();
    event.stopPropagation();

    const form = $('#newCommentForm')[0];

    if(form.checkValidity() === false) {
        form.classList.add('was-validated');
    } else {
        const comment = $('#newComment').val();
        const Tags = await getMentionTags('newComment');
        createCommentExt(comment, Tags);
    }
}

// COMMENTS
function initComments(issueId, refresh = false, showModal = true) {
    let issue = null;
    if(issueId && typeof issueId == 'number')  {
        currentDetailIssueId = issueId;
        issue = getIssueById(issueId);
        getCommentsExt(issue, refresh, showModal);
    } else {
        issue = currentIssues[0];
        renderComments(issue, showModal);
    }
}

function renderComments(issue, showModal = true) {
    $('#commentsCarousel').html(spinnerHtml());
    $('#issueDetail').html(spinnerHtml());
    const issueDetailCardHtml = issueDetailHtml(issue);
    const slidesHtml = commentsHtml(issue);
    $('#commentsCarousel').html('');
    $('#issueDetail').html('');
    $('#issueDetail').append(issueDetailCardHtml);
    $('#commentsCarousel').append(slidesHtml);
    initIssueListeners();
    if(showModal) {
        $('#commentsModal').modal('show');
        if(currentEditContext != 'marker') {
            currentEditContext = 'detail';
        }
    }
}

function commentsHtml({ comments }) {
    if(!comments || (comments && comments.length == 0)) {
        return `
        <div class="carousel-item active">
            <div class="container mt-3 mb-3">
                <div class="row">
                    <div class="col-12">
                        <p style="font-size: 14px; font-weight: 700;" > — no comments yet — </p>
                    </div>
                </div>
            </div>
        </div>`;
    }
    const slidesHtml = comments.map((comment,i) => {
        try {
            comment.file = parsePreviewImagePath(comment.Attachment);
        } catch(exc) {
            exceptionOccurred(exc, 'JSON.parse(comment.Attachment)');
        }

        const lastElementActive = (i === comments.length-1 );
        return commentSlideHtml(comment, lastElementActive);
    });
    return slidesHtml;
}

function commentSlideHtml(comment, active) {
    const timeAgo = getCreationTimeAgo(comment.CreationDate);
    let createdBy = '';

    if(comment.CreatedByUsername) {
        createdBy = `<a href="#">@${comment.CreatedByUsername}</a> commented ${timeAgo}`;
    } else {
        createdBy = `You left a comment ${timeAgo}`;
    }

    return `
        <div class="carousel-item ${active ? 'active' : ''}">
            <img class="img-fluid rounded carouselImage pointer" src="${comment.file}" alt="ATTACHMENT" />

            <div class="commentContainer container mt-3 mb-3">
                <div class="row card">
                    <div class="col-12">
                        <img width="24" height="24" src="res/mdi/account-badge.svg" alt="CANCEL" />
                        ${createdBy}
                    </div>
                    <div class="col-12">
                        <p class="comment">${comment.Comment}</p>
                    </div>
                </div>
            </div>
        </div>
    `;
}

let mentionUsers = null;
// MENTIONS
function initMentions(members) {

    if(!mentionUsers) {
        mentionUsers = members.map(function(user) {
            return {
                id: user.User_ID,
                name: user.Username,
                display: user.Username + " (" + user.RoleDescription + ", " + user.OrgName + ")",
                type: 'contact',
                icon: 'fa fa-user',
            };
        });
    }

    // Initialize the mentions plugin
    $('.commentTextarea').mentionsInput({
        triggerChar: '@',
        onDataRequest: function (mode, query, callback) {
            const filteredUsers = mentionUsers.filter(function(user) {
                return user.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            });
            callback.call(this, filteredUsers);
        }
      });
}
function getMentionTags(textareaId) {
    return new Promise((resolve, reject) => {
        if(!textareaId || !textareaId.length) {
            resolve(null);
        }

        $(`#${textareaId}`).mentionsInput('getMentions', function(data) {
            const mentionedUserIds=$.map(data,function(v,k){
                return v.id;
            });
            if(!mentionedUserIds.length) {
                resolve(null);
            }
            const Tags = mentionedUserIds.join(', ');
            resolve(Tags);
        });
    });
}
// API calls
function getFilteredIssues() {
    $('#issueCards').html(spinnerHtml(false));
    if(Object.keys(currentFilter).length > 1) {
        isFilterActive = true;
        $('[data-bs-target="#filters"]').removeClass('btn-primary').addClass('btn-warning');
        $('#issueCardsContainer').off('scroll', onScroll);

        if(state.isPdf) {
            const { field } = FILTERS.PDF_PAGE;
            currentFilter[field] = currentPage;
        }

        const filter = JSON.stringify(currentFilter);
        getIssuesExt(100, 0, false, filter);
    } else {
        isFilterActive = false;
        $('[data-bs-target="#filters"]').removeClass('btn-warning').addClass('btn-primary');
        getIssuesExt();
    }
}

async function getMoreIssues() {
    try {
        state.isLoading = true;
        $('#issueCardsContainer').off('scroll', onScroll);
        const take = currentIssues.length + loadNumberOfIssuesAtOnce;
        const skip = currentIssues.length;
        await getIssuesExt(take, skip, true);
        state.isLoading = false;
    } catch(exc) {
        exceptionOccurred(exc);
    }
}

function getIssuesExt(take = loadNumberOfIssuesAtOnce, skip = 0, concatIssues = false, filter = '') {
    state.isQuerying = true;

    if(filter.length == 0 && state.isPdf) {
        const currentPageFilter = { mode: 'AND' , PdfPage: '' + currentPage };
        filter = JSON.stringify(currentPageFilter);
    }

    const params = {
        token: bimpoolToken,
        urn: currentUrn || null,
        rcode: currentRcode || null,
        Filters: filter,
        All: true,
        type:'issue',
        Skip: skip,
        Take: take,
        IsFiltered: state.isPdf ? false : filter.length > 0,
    };
    return fetch(`${API.BASE}${API.GET_ISSUES}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    })
    .then(response => response.json())
    .then((data) => {
        const { issues, issueStatus, issuesPriorities, members, userId } = data;

        if(userId)
            currentUserId = userId;

        if(concatIssues) {
            currentIssues = updateOrAddIssues(currentIssues, issues);
        } else {
            currentIssues = issues;
        }

        renderCurrentIssues();

        if(currentIssues.length >= loadNumberOfIssuesAtOnce && !isFilterActive)    //scroll listener only if not all issues loaded yet
            $('#issueCardsContainer').on('scroll', onScroll);

        if(!state.isInitialized) {
            initMentions(members);
            initDropdownOptions(issueStatus, issuesPriorities, members);
            state.isInitialized = true;
        }
        state.isQuerying = false;;
    });
}

function updateOrAddIssues(currentIssues, newIssues) {
    const updatedIssues = currentIssues.slice();

    for(const newIssue of newIssues) {
        const index = updatedIssues.findIndex(issue => issue.ID === newIssue.ID);

        if(index !== -1) {
            updatedIssues[index] = newIssue;
        } else {
            // If it doesn't exist, add it to the array
            updatedIssues.push(newIssue);
        }
    }

    return updatedIssues;
}

function getCommentsExt(issue, refreshComments = false, showModal = true) {
    state.isQuerying = true;
    if(issue.comments && !refreshComments) {    //onMarkerClick & comments already loaded
        currentDetailIssueId = issue.ID;
        renderComments(issue);
        state.isQuerying = false;;
        return;
    }

    const params = {
        token: bimpoolToken,
        urn: currentUrn,
        Topic_ID: issue.ID,
    };
    fetch(`${API.BASE}${API.GET_ISSUE_COMMENTS}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
    })
    .then(response => response.json())
    .then((data) => {
        issue.comments = data;
        if(issue.comments.length > 0 && issue.comments[issue.comments.length-1] && issue.comments[issue.comments.length-1].Attachment) {
            issue.IssuePreview = issue.comments[issue.comments.length-1].Attachment;
            if(currentEditContext != 'marker') {
                renderCurrentIssues();
            }
        }
        renderComments(issue, showModal);
        state.isQuerying = false;
    });
}

function createIssueExt(issue) {
    hideAllMarkers();
    state.isQuerying = true;

    let comment = null, OnSiteAttachment = null;
    if(issue.comment) {
        comment = issue.comment;
        OnSiteAttachment = selectedImage || null;
        delete issue.comment;
        delete issue.OnSiteAttachment;
    }

    let Screenshot = null;

    if(issue.Screenshot) {
        Screenshot = issue.Screenshot;
        delete issue.Screenshot;
    }

    if(state.isPdf) {
        issue.PdfPage = parseInt(currentPage);
    }

    const params = {
        token: bimpoolToken,
        urn: currentUrn,
        action: 'create',
        issue: issue,
        filename: currentFilename,
    };
    fetch(`${API.BASE}${API.SAVE_ISSUE}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(async (data) => {
        const onLine = await checkOnlineStatus();

        if (data && data.length) {
            const createdIssue = data[0];

            if (onLine) {
                currentDetailIssueId = createdIssue.ID;
            }

            if (currentMarkerPoint) {
                createdIssue.Marker = currentMarkerPoint;
                currentMarkerPoint = null;
            }

            if (Screenshot) {
                saveScreenshotExt(Screenshot, issue.phantomId);
                createdIssue.IssuePreview = Screenshot;
            }

            if (comment && comment.Comment && comment.Comment.length) {
                createCommentExt(comment.Comment, comment.Tags, issue.phantomId, false);

                if (OnSiteAttachment) {
                    createdIssue.IssuePreview = OnSiteAttachment;
                    comment.Attachment = OnSiteAttachment;
                }

                createdIssue.comments = [comment];
            }

            currentIssues.unshift(createdIssue);
            renderCurrentIssues();
        } else {
            getIssuesExt();
        }

        resetForm('newIssueForm');
        $('#newIssueModal').modal('hide');

        if (markupExtension.activeStatus) {
            markupExtension.deactivate();
        }

        cancelMarker();
        showToast(MESSAGES.SUCCESS, MESSAGES.ISSUE_CREATE_SUCCESS);
        state.isQuerying = false;
    });
}

function saveScreenshotExt(Screenshot, phantomId = null) {
    const params = {
        token: bimpoolToken,
        urn: currentUrn,
        Topic_ID: currentDetailIssueId,
        phantomId: phantomId,
        Screenshot,
    };
    fetch(`${API.BASE}${API.SAVE_ISSUE_SCREENSHOT}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then((data) => {
        renderCurrentIssues();
        showToast(MESSAGES.SUCCESS, MESSAGES.SCREENSHOT_CREATE_SUCCESS);
    });
}

function updateIssueExt(issue) {
    const params = {
        token: bimpoolToken,
        urn: currentUrn,
        issue,
    };
    return fetch(`${API.BASE}${API.UPDATE_ISSUE}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then((data) => {
        $('#editIssueModal').modal('hide');
        showToast(MESSAGES.SUCCESS, MESSAGES.EDIT_SUCCESS);
        updateIssueList();
        returnToPreviousContext();
        currentDetailIssueId = null;
        currentEditContext = null;
    });
}

function updateMarkupExt(issue) {
    const params = {
        token: bimpoolToken,
        urn: currentUrn,
        issue,
    };
    return fetch(`${API.BASE}${API.UPDATE_ISSUE_MARKUP}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then((data) => {
        $('#editIssueModal').modal('hide');
        showToast(MESSAGES.SUCCESS, MESSAGES.EDIT_SUCCESS);
        updateIssueList(data);
    });
}

function updateMarkerExt() {
    const params = {
        token: bimpoolToken,
        urn: currentUrn,
        Topic_ID: currentDetailIssueId,
        Marker: confirmedMarkerPoint,
    };
    return fetch(`${API.BASE}${API.SAVE_ISSUE_MARKER}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then((data) => {
        showToast(MESSAGES.SUCCESS, MESSAGES.MARKER_UPDATE_SUCCESS);
        const issue = getIssueById(currentDetailIssueId);
        issue.Marker = currentMarkerPoint;

        unbindMarkerClickEvents();
        $('.markerSelectorIcon').removeClass('d-none');
        $('.markerSelectedIcon').addClass('d-none');
        toggleMarkersUI(false);

        returnToPreviousContext();
        editingIssueMarker = false;
        currentMarkerPoint = null;
        confirmedMarkerPoint = null;
        currentDetailIssueId = null;
    });
}

function createCommentExt(text, tags, phantomId = null, showModal = true) {
    let newComment = {
        Comment: text,
        Topic_ID: currentDetailIssueId,
    };

    if(tags) {
        newComment.Tags = tags;
    }

    const params = {
        token: bimpoolToken,
        urn: currentUrn,
        rcode: currentRcode,
        Topic_ID: currentDetailIssueId,
        phantomId: phantomId,
        comment: newComment,
        OnSiteAttachment: selectedImage || null,
    };
    fetch(`${API.BASE}${API.SAVE_ISSUE_COMMENT}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params),
    })
    .then(response => response.json())
    .then(async (data) => {
        const onLine = await checkOnlineStatus();

        let attachment;
        if (onLine && data.Table && data.Table.length) {
            attachment = data.Table[0].Attachment;
        } else if (!onLine && data.OnSiteAttachment) {
            attachment = data.OnSiteAttachment;
        }

        if (attachment) {
            const issue = onLine ? getIssueById(currentDetailIssueId) : currentIssues[0];
            issue.IssuePreview = attachment;
            renderCurrentIssues();
        }

        resetForm('newCommentForm');
        initComments(currentDetailIssueId, true, showModal);
        showToast(MESSAGES.SUCCESS, MESSAGES.COMMENT_CREATE_SUCCESS);
    });
}

function updateIssueList(data) {
    if(data && data.length) {
        let issueToUpdate = getIssueById(data[0].ID || data[0].Topic_ID)
        let indexToUpdate = currentIssues.indexOf(issueToUpdate);
        currentIssues[indexToUpdate] = data[0];
    }
}

function getResourceUrls() {
    // Get the list of performance entries
    const entries = performance.getEntries();

    // Filter the entries to include only the resource types you are interested in (e.g., script, image, stylesheet, etc.)
    const resourceEntries = entries.filter(entry =>
    entry instanceof PerformanceResourceTiming
    );

    // Extract the URLs from the resource entries
    const resourceUrls = resourceEntries.map(entry => entry.name);

    // Log the URLs of the loaded resources
    console.log(resourceUrls);
    return resourceUrls;
}

function onModelCachedSuccessfully() {
    const postData = {
        token: bimpoolToken,
        urn: currentUrn,
        filename: currentFilename,
        deviceInfo: getBrowser(),
    };

    fetch(`${API.BASE}${API.ON_MODEL_CACHED}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(postData)
    })
    .catch(error =>  exceptionOccurred(error, arguments.callee.name));


}

//PDF handling
function setPreviousPdfPage() {
    disablePdfControls('previousPdfPage');
    setPdfPage(-1);
}

function setNextPdfPage() {
    disablePdfControls('nextPdfPage')
    setPdfPage(1);
}

async function setPdfPage(direction) {
    hideAllMarkers(false);
    const newPage = currentPage + direction;
    if(newPage >= 1 && newPage <= currentNumPages) {
        currentPage = newPage;
        await loadPdf();
        await getIssuesExt();
        if(markersVisible) {
            showAllMarkers();
        }
    }

}

function disablePdfControls(clickedControlId) {
    const prevButton = $('#previousPdfPage')[0];
    const nextButton = $('#nextPdfPage')[0];
    const clickedButton = $(`#${clickedControlId}`)[0];

    prevButton.disabled = true;
    nextButton.disabled = true;
    clickedButton.innerHTML = spinnerHtml(false);
}

function enablePdfControls() {
    const prevButton = $('#previousPdfPage')[0];
    const nextButton = $('#nextPdfPage')[0];

    prevButton.innerHTML = '<img width="24" height="24" src="res/mdi/skip-previous.svg" alt="PREVIOUS PAGE" />';
    nextButton.innerHTML = '<img width="24" height="24" src="res/mdi/skip-next.svg" alt="NEXT PAGE" />';

    if(currentPage === 1) {
        prevButton.disabled = true;
    } else {
        prevButton.disabled = false;
    }

    if(currentPage === currentNumPages) {
        nextButton.disabled = true;
    } else {
        nextButton.disabled = false;
    }
}

// MARKERS
function initMarker(event) {
    if(event) {
        event.stopPropagation();
        event.preventDefault();
    }
    toggleMarkersUI(true);
    //delegate the mouse click event
    bindMarkerClickEvents();
    //delegate the camera change event
    viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, onCameraChange);
    showToast(MESSAGES.INFO, MESSAGES.SET_MARKER);
}

function bindMarkerClickEvents() {
    if (isMobileDevice()) {
        $(viewer.container).bind("touchend", onMarkerSet);
    } else {
        $(viewer.container).bind("click", onMarkerSet);
    }
}

function unbindMarkerClickEvents() {
    $(viewer.container).unbind("click", onMarkerSet);
    $(viewer.container).unbind("touchend", onMarkerSet);
}

function initEditIssueMarker({target}) {
    const targetIsIcon = $(target).prop('nodeName').toLowerCase() === 'i';

    if(targetIsIcon) {
        target = $(target).closest('.issueControlButton')[0];
    }

    const issueId = $(target).data('issue-id');
    if(currentEditContext != 'marker') {
        const context = $(target).data('context');
        currentEditContext = context;
    }
    editingIssueMarker = true;
    initMarker();
    const issue = getIssueById(issueId);
    currentDetailIssueId = issueId;

    if(issue && issue.Marker) {
        const markerToEdit = typeof issue.Marker == 'string' ? JSON.parse(issue.Marker) : issue.Marker;
        drawMarker(markerToEdit)
    }
    $('#markerIssueDetail').html(issueDetailHtml(issue, false));
    $('.modal').modal('hide');
}

async function submitMarker() {
    confirmedMarkerPoint = JSON.stringify(currentMarkerPoint);
    $('.markerSelectorIcon').addClass('d-none');
    $('.markerSelectedIcon').removeClass('d-none');
    $('#submitMarker').prop('disabled', true);
    $('.markerControl').addClass('d-none');
    if(editingIssueMarker) {
        await updateMarkerExt();
        returnToPreviousContext();
    } else {
        $('#newIssueModal').modal('show');
    }
}

function cancelMarker() {
    confirmedMarkerPoint = null;
    $("div[id^='issueMarker_']").remove();
    toggleMarkersUI(false);
    editingIssueMarker = false;
    unbindMarkerClickEvents();
    $('.markerSelectorIcon').removeClass('d-none');
    $('.markerSelectedIcon').addClass('d-none');
    $('#submitMarker').prop('disabled', true);
    $('.markerControl').addClass('d-none');
    returnToPreviousContext();
}

function toggleMarkersUI(show = false) {
    hideAllMarkers(false);
    if(show) {
        $('.control').addClass('d-none');
        $('.markerControl').removeClass('d-none');
        $('.modal').modal('hide');
    } else {
        $('.toast').toast('hide');
        $('.control').not('#hideAllMarkers').removeClass('d-none');
        $('.markerControl').addClass('d-none');
        $('#markerIssueDetail').html('');
        // $(modalId).modal('show');
        if(!editingIssueMarker) {
            $('#newIssueModal').modal('show');
        }
    }
}

function showAllMarkers() {
    hideAllMarkers(false);
    markersVisible = true;
    viewer.addEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, onCameraChange);
    for(let i=0;i<currentIssues.length; i++) {
        const issue = currentIssues[i];

        if(state.isPdf && issue.PdfPage && typeof issue.PdfPage == 'number' && issue.PdfPage != currentPage) {
            continue;
        }

        if(issue.Marker) {
            let point;
            if(typeof issue.Marker == 'string') {
                point = JSON.parse(issue.Marker);

            } else {
                point = issue.Marker;
            }
            drawMarker(point, issue.ID);
        }
    }
    $('#showAllMarkers').addClass('d-none');
    $('#hideAllMarkers').removeClass('d-none');
}

function hideAllMarkers(setStatus = true) {
    if(setStatus) {
        markersVisible = false;
    }
    $('div[id^="issueMarker_"]').remove()
    viewer.removeEventListener(Autodesk.Viewing.CAMERA_CHANGE_EVENT, onCameraChange);
    $('#showAllMarkers').removeClass('d-none');
    $('#hideAllMarkers').addClass('d-none');
}

function onMarkerSet(event) {
    event.preventDefault();
    event.stopPropagation();
    const { type } = event;

    $('#submitMarker').prop('disabled', false);
    let x, y;

    if (type.startsWith('touch')) {
        const touch = event.originalEvent.touches[0] || event.originalEvent.changedTouches[0];
        x = touch.pageX;
        y = touch.pageY;
        //} else if(type.startsWith('mouse')) {
    } else {
        x = event.clientX;
        y = event.clientY;
    }

    if(!x)
        x = event.clientX;

    if(!y)
        y = event.clientY;

    const screenPoint = { x, y };
    const worldCoords = viewer.impl.clientToWorld(screenPoint.x, screenPoint.y);
    if(!worldCoords) {
        return;
    }
    const worldPoint = worldCoords.point;

    if(worldPoint) {
      drawMarker({ x: worldPoint.x, y: worldPoint.y, z: worldPoint.z });
    }
}

function drawMarker(point, issueId = null) {
    const screenPoint = viewer.worldToClient(
        new THREE.Vector3(point.x, point.y, point.z)
    );

    if(!issueId) {    //createIssue-case: clear canvas
        $('div[id^="issueMarker_"]').remove();
        issueId = 'newIssue';
    }

    const width = 64, height = 64;
    const containerHtml = '<div id="issueMarker_' + issueId + '"></div>';
    $(viewer.container).append(containerHtml);

    const $container = $(`#issueMarker_${issueId}`);
    $container.css({
        'width':  width + 'px',
        'height': height + 'px',
        'position': 'absolute',
        'overflow': 'visible'
    });

    $container.append('<img id="issueMarkerSvg_'+issueId+ '" class="issue-marker pointer" width="'+width+'" height="'+height+'" src="res/mdi/map-marker-star.svg" alt="MARKER">');
    // onload="onMarkerLoaded(event)"
    const svgElement = $(`#issueMarkerSvg_${issueId}`)[0];
    svgElement.style.width = width + 'px';
    svgElement.style.height = height + 'px';
    svgElement.setAttribute('width', ''+ width);
    svgElement.setAttribute('height', ''+ height);

    $container.css({
        'left': screenPoint.x - width/2,
        'top': screenPoint.y - width,
        // 'left': screenPoint.x,
        // 'top': screenPoint.y,
    });

    point.radius = width/2;
    $container.data('3DData', JSON.stringify(point));
    currentMarkerPoint = point;

    $('#issueMarker_'+issueId).on('click', onMarkerClick)
}

function onMarkerLoaded(event) {
    // console.log("onMarkerLoaded", event)
    // const svgElement = event.target;
    // svgElement.setAttribute('fill', '#FF0000');
};

function onMarkerClick(event) {
    event.stopPropagation();
    event.preventDefault();
    const issueId = parseInt(event.currentTarget.id.replace('issueMarker_', ''));
    if(Number.isNaN(issueId))   //createIssue-case
        return;

    currentDetailIssueId = issueId;
    currentEditContext = 'marker';
    const issue = getIssueById(issueId);
    getCommentsExt(issue);
}

function onCameraChange() {
    const $markers = $("div[id^='issueMarker_']").get();

    for(let i in $markers){

        const $marker = $markers[i];
        const $markerContainer = $(`#${$marker.id}`);

        const data = $markerContainer.data('3DData');
        const markerPoint = JSON.parse(data);

        const screenPoint = viewer.worldToClient(new THREE.Vector3(
            markerPoint.x,
            markerPoint.y,
            markerPoint.z,
        ));
        //update the SVG position.
        $markerContainer.css({
            'left': screenPoint.x - markerPoint.radius*2,
            'top': screenPoint.y - markerPoint.radius,
        });
    }
}

// HELPERS
function getIssueById(id) {
    return currentIssues.filter(function({ID}) { return ID === parseInt(id) })[0];
}

function getBrowser() {
    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1) {
      return 'Opera';
    } else if(navigator.userAgent.indexOf("Edg") != -1) {
      return 'Edge';
    } else if(navigator.userAgent.indexOf("Chrome") != -1) {
      return 'Chrome';
    } else if(navigator.userAgent.indexOf("Safari") != -1) {
      return 'Safari';
    } else if(navigator.userAgent.indexOf("Firefox") != -1) {
      return 'Firefox';
    } else if((navigator.userAgent.indexOf("MSIE") != -1) || (!!document.documentMode == true)) {
      return 'IE';
    } else {
      return 'unknown';
    }
}

function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

function getViewerScreenshot () {
    try {
        let markupExtension = viewer.getExtension("XCOMarkup");
        markupExtension.activate();
        return new Promise((resolve, reject) => {
            if(markupExtension?.activeStatus){
                let markup=markupExtension.core.generateData();
                viewer.getScreenShot(viewer.container.clientWidth, viewer.container.clientHeight,
                    function (blobURL) {
                        getScreenshotWithCanvas(blobURL).then(
                            function(screenshot){
                                if(screenshot) {
                                    let resolveObj={screenshot:screenshot,markup:markup};
                                    markupExtension.deactivate();
                                    resolve(resolveObj);
                                }
                        });
                    }
                );
            }
        });
    } catch(exc) {
        return new Promise((resolve, reject) => {
            const dataUrl = $('canvas')[0].toDataURL();
            resolve({screenshot: dataUrl, markup: null})
        })
    }
 }

function getScreenshotWithCanvas (src){
    let markupExtension = viewer.getExtension("XCOMarkup");
    markupExtension.activate();
    markupExtension.core.show();
    return markupExtension.blobToCanvas(viewer.container.clientHeight, viewer.container.clientWidth, src);
}

function showToast(title, message, delay = null) {
    const toast = $('.toast');
    toast.toast('hide');
    $('.toast-header strong').text(title);
    $('.toast-body').text(message);
    toast.data('data-bs-autohide', true);
    toast.toast('show');
}

function generateRandomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

function exceptionOccurred(error, functionName) {
    console.error(`An error occurred in function ${functionName}:`, error);
}

function watchState() {
  Object.keys(state).forEach((key) => {
    let value = state[key];

    Object.defineProperty(state, key, {
      get: function () {
        return value;
      },
      set: function (newValue) {
        value = newValue;
        handleStateChange(key);
      },
    });
  });
}

function handleStateChange(key) {

  if(key === 'isQuerying') {
    if(state.isQuerying) {
        disableSubmitButtons();
    } else {
        $('.spinner').addClass('d-none');
        $('.saveIcon').removeClass('d-none');
    }
  }
}

async function checkOnlineStatus() {
    if (!navigator.onLine) {
        return false;
    }

    const timestamp = Date.now();
    try {
        const response = await fetch(`res/img/1x1.png?${timestamp}`, {
            cache: 'no-store'
        });

        return response && response.status === 200 ? true : false;
    } catch (error) {
        return false;
    }
}

function apiBaseUrl() {
    const hostname = self.location.hostname;

    if (hostname.includes('bimpool.io')) {
        return 'https://bimpool.io';
    } else if (hostname.includes('mobileviewer.xconstruction.ai')) {
        return 'https://test.xconstruction.ai';
    } else {
        return 'http://bim';  // Default for local development.
    }
}

async function getVersion() {
    const versionResp = await fetch('version.txt');
    if (versionResp.ok) {
        const version = await versionResp.text();
        return version;
    }
    return 'developing';
}